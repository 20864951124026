import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IPage } from '@/interfaces/page';
import { classNames } from '@/shared/helpers';
import { IAppDataContent } from '@/interfaces/appdata';
import { DefaultLayout } from 'src/layouts/DefaultLayout';
import { ICategoryWithProducts } from '@/interfaces/products';

import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

import './style.scss';
import { track } from '@/shared/analytics';

const pages = ({ pageContext }: PagesProps) => {
  const { templateData } = pageContext.appData;
  const backgroundHeader = pageContext.page.institutionalBgImage;

  const defaultLayoutData = {
    pageContext,
    templateData,
    title: pageContext.page.menu,
    backgroundHeader,
    domNodeToExtractHeight: '.institutional-page-header',
    isInstitutionalPage: true,
  };

  useEffect(() => {
    track('institutionalPageView', { template: 'virgoA' });
  }, []);

  return (
    <DefaultLayout {...defaultLayoutData}>
      <article className="institutional-page-container">
        <div className="institutional-page-header">
          <div
            className={classNames(
              'institutional-page-title',
              backgroundHeader ? 'with-bg' : ''
            )}
          >
            <h1
              dangerouslySetInnerHTML={{ __html: pageContext.page.title || '' }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: pageContext.page.subTitle || '',
              }}
            />
          </div>
        </div>

        {pageContext.page.sections && pageContext.page.sections.length > 0 ? (
          <ul className="institutional-page-content">
            {pageContext.page.sections?.map((section, index) => (
              <li
                key={index}
                className={classNames(
                  section.images.length > 0
                    ? 'section-grid-layout'
                    : 'section-flex-layout',
                  !!pageContext.page.institutionalBgImage ? 'reverse-order' : ''
                )}
              >
                {section.images.length > 0 ? (
                  <div className="section-image">
                    <Swiper
                      rewind={true}
                      navigation={true}
                      slidesPerView={1}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {section.images.map((image, index) => (
                        <SwiperSlide key={image.url}>
                          <img src={image.url} alt={`slide ${index + 1}`} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                ) : null}
                <div className="section-config">
                  <h1 dangerouslySetInnerHTML={{ __html: section.title }} />
                  <p dangerouslySetInnerHTML={{ __html: section.subTitle }} />
                  {section.buttonCta && (
                    <a href={section.buttonCta.url}>
                      <button>{section.buttonCta.text}</button>
                    </a>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </article>
    </DefaultLayout>
  );
};

interface PagesProps {
  pageContext: {
    appData: IAppDataContent;
    lang: string;
    page: IPage;
    categoriesWithProducts: ICategoryWithProducts[];
    currentPath: string;
    pathLang: string;
    widgetTarget: string;
  };
}

export default pages;
